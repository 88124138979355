.contactme {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .contactOverlay {
    width: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
    background-color: #fafafa;
  }
  .form {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 1320px;
    margin: 0 auto;
  }
  form {
    width: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: white;
    overflow: hidden;
    border: solid 0.5px black;
  }
  form button {
    width: 190px;
    height: 55px;
    border: 0;
    border-radius: 5px;
    background-color: #001517;
    -webkit-transition: 0.5s linear;
    -o-transition: 0.5s linear;
    transition: 0.5s linear;
    color: white;
    font-weight: bold;
  }
  form button:hover {
    background: #212121;
    color: #fff;
    transition: 250ms;
  }
  .formWord {
    width: 100%;
    padding: 4%;
  }
  .formWord h2 {
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;
  }

  .status {
    color: green
  }
  .formWord input {
    width: 100%;
    height: 50px;
    border: 0;
    margin-top: 1%;
    padding-left: 10px;
    margin-bottom: 1%;
    background: #ffffff;
    border-bottom: solid 1px black;
    /* -webkit-box-shadow: 0px 0px 48px 0px rgb(218, 218, 218);
    box-shadow: 0px 0px 48px 0px rgb(218, 218, 218); */
  }
  .formWord textarea {
    width: 100%;
    margin-bottom: 2%;
    border: 0;
    margin-top: 2%;
    height: 350px;
    color: black;
    border: solid 1px black;
    /* -webkit-box-shadow: 0px 0px 48px 0px rgb(218, 218, 218);
    box-shadow: 0px 0px 48px 0px rgb(218, 218, 218); */
    padding: 10px 10px 10px 10px;
    resize: none;
  }

  @media only screen and (max-width: 1024px) {
    .contactme h2 {
        font-size: 27px;
    }
  }