video {
    object-fit: cover;
    width: auto;
    min-height: 100vh;
    position: fixed;
    z-index: -1;
    margin: -40px 0 0 0;
}

.hero-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); 
    object-fit: contain;
}

.hero-container > h1 {
    color: #fff;
    font-size: 60px;
    margin-top: -100px;
    text-align: center;
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Didact Gothic', 'sans-serif';
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.team-scroll {
    cursor: pointer;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 70px;
        margin-top: -150px;
        padding: 30px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        color: #fff;
        font-size: 40px;
        margin-top: -100px;
    }

    .hero-container > p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .hero-container .btn {
        width: 100%;
        text-align:center;
        margin: 12px auto; 

    }

}


