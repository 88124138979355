* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Didact Gothic', sans-serif;
}

video::-webkit-media-controls {
  display:none !important;
}

h1 {
  font-family: 'Merriweather', 'serif';
  max-width: 1120px;
  margin: 0 auto;
}


.team-wrapper {
  background-color: #fafafa;
}

.team-title {
  text-align: left;
  max-width: 1220px;
  margin: 0 auto;
  padding: 4rem 0rem 0rem 3rem;
}

.statement {
  max-width: 1220px;
  margin: 0 auto;
  padding: 3rem;
  
}

.statement-wrapper {
  background-color: #fff;
}

.statement p {
  
  margin: 0 auto;
  font-size: 22px;
}

.titles {
  
  text-align: left;
  margin-top: 30px;
}

.previous-work-title {
  max-width: 1220px;
  padding: 4rem 0rem 0rem 4rem;
}

.nezen-logo {
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .previous-work-title {
    padding: 4rem 0rem 0rem 0rem;
  }

  .statement p {
    font-size: 19px;
  }
  
}

@media only screen and (max-width: 440px) {
  .previous-work-title {
    font-size: 25px;
  }
  
}
